<template>
  <div>
    <!--begin::Content header-->
    <!-- <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Don't have an account yet?
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'register' }"
      >
        Sign Up!
      </router-link>
    </div> -->
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-lg-20" style="margin-bottom: 5px!important;">
        <h3 class="font-size-h1 text-success">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your username and password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <!-- <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Use account <strong>ikram@act.id</strong> and password
            <strong>789</strong> to continue.
          </div>
        </div> -->

        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
          v-if="errors"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.username.$model"
            placeholder="username"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Username is required and a valid username address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            placeholder="Password"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
            <label class="checkbox checkbox-outline m-0 text-muted">
            <input type="checkbox" name="remember">Remember me
            <span></span></label>
          </div>
        </div>
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <!-- <router-link class="text-dark-60 text-hover-success text-success my-3 mr-2" id="kt_login_forgot" :to="{ name: 'forgot-password' }">
            Forgot Password ?
          </router-link> -->
          <v-spacer />
          <button :disabled="loadingLogin" ref="kt_login_signin_submit" class="btn btn-success font-weight-bold px-9 py-4 my-3 font-size-3">
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
</style>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { LOGIN } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        username: "",
        password: ""
      },
      loadingLogin: false
    };
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      var formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      
      this.loadingLogin = true;
      this.$store.dispatch(LOGIN, formData).then(() => {
          this.$router.push({ name: "dashboard" })
          submitButton.classList.remove( "spinner", "spinner-light", "spinner-right");
          this.loadingLogin = false;
        }).catch(() => {
            submitButton.classList.remove( "spinner", "spinner-light", "spinner-right");
            this.loadingLogin = false;
        });
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    if(this.isAuthenticated) this.$router.push({ name: "dashboard" });
  }
};
</script>
